* {    
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'sans-serif';
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
/* SUPER IMPORTANT */
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 1.2em;
}

input::-webkit-date-and-time-value{ 
  text-align:left; 
}

.App {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* img {
  max-width: 300px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
  border: 2px solid blue;
  display: flex;
  justify-content: flex-end;
}

.delete-button {
  position: absolute;
  display: flex;
  padding: 7px;
  margin: 10px;
  font-weight: 600;
  background-color: grey;
  border-radius: 16px;
  color: white;
  border: none;
  
}